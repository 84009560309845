import React from "react"
import "./opportunities.scss"

import { Navigation, Pagination } from "swiper"

import { Swiper, SwiperSlide } from "swiper/react"
import SliderNavigation from '../../components/SharedComponents/SliderNavigation'

// Import Swiper styles
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/pagination"
import "swiper/css/scrollbar"
import { createMarkup } from "../../../utils"

export default function Opportunities(props) {
  const { data, slider, locale } = props
  const shouldRenderArrows = slider.length > 1

  const swiperRef = React.useRef(null)
  return (
    <>
      <section className="invest-opportunities">
        <div className="container">
          <div className="col-group">
            <div className="col-dt-7 left-col">
              <div className="title-desc-wrapper">
                <div className="big-title h2">{data.title}</div>
                <div
                  className="desc"
                  dangerouslySetInnerHTML={createMarkup(data.text)}
                ></div>
              </div>
            </div>
            <div className="col-dt-5 right-col">
              <div className="logo-portrait desktop-show">
                <div className="logo-portrait-wrapper">
                  <img src="/images/invest/portrait-logo.svg" alt="Invest" />
                </div>
              </div>
            </div>
          </div>
          <Swiper
            modules={[Navigation, Pagination]}
            pagination={{
              el: ".swiper-pagination",
              clickable: true,
              renderBullet: function (index, className) {
                return '<span className="' + className + '">' + "</span>"
              },
            }}
            id="investswiper"
            spaceBetween={80}
            slidesPerView={1}
            slidesPerGroup={1}
            grabCursor={"true"}
            ref={swiperRef}
            dir={locale === "en" ? "ltr" : "rtl"}
          >
            {slider.map((item, index) => (
              <SwiperSlide key={index}>
                <div className="col-group second-col-group">
                  <div className="col-dt-7 left-col">
                    <div className="invest-opportunity-img-wrapper">
                      <img src={item.sliderImage?.localFile?.publicURL} alt={item.sliderTitle} />
                      <div className="number">0{index +1}</div>
                    </div>
                  </div>
                  <div className="col-dt-5 right-col slider-wrapper">
                    <div className="slider-wrapper-inner">
                      <div className="title h2">{item.sliderTitle}</div>
                      <div
                        className="swiper-desc"
                        dangerouslySetInnerHTML={createMarkup(item.sliderText)}
                      ></div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
            <div className="swiper-nav-wrapper">
              <div className="col-group">
                <div className="col-dt-7 left-col"></div>
                <div className="col-dt-5 right-col slider-wrapper">
                  <div className="slider-wrapper-inner">
                  {shouldRenderArrows && <SliderNavigation ref={swiperRef} />}

                    <div className="swiper-pagination swiper-pagination-adjust"></div>
                  </div>
                </div>
              </div>
            </div>
          </Swiper>
        </div>
      </section>
    </>
  )
}
