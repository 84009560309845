import React from "react"
import { useContext } from "react"
import { string_translation } from "../../../utils"
import {useStaticQuery, graphql} from "gatsby"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"

import "./message.scss"
import { createMarkup } from "../../../utils"

export default function BaordMessage({data}) {

  const {
    locale, // ar
  } = useContext(TranslateUrlsContext)

  const {stringTranslations} = useStaticQuery(graphql`query {
    stringTranslations {
      mfb_ar:translateString(language: AR, string: "MESSAGE FROM THE BOARD")
      mfb_en:translateString(language: EN, string: "MESSAGE FROM THE BOARD")
    }
  }`)

  return (
    <>
      <section className="author">
        <div className="container">
          <div className="news-author-wrapper">
            <div className="col-group">
              <div className="col-dt-5">
                <div className="author-img-wrapper">
                  <img src={data.image?.localFile?.publicURL} alt={data.message}/>
                </div>
              </div>
              <div className="col-dt-2">
                <div className="double-quotes">
                  <img src="/images/news/double-quotes.svg" alt="Double Quotes" />
                </div>
              </div>
              <div className="col-dt-5">
                <div className="author-info-wrapper">
                  <div className="title-combo">
                    <div className="name h2">{string_translation(stringTranslations,"mfb", locale)}</div>
                  </div>
                  <div className="parag" dangerouslySetInnerHTML={createMarkup(data.message)}>
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
