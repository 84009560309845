import React, { useState, useContext } from "react"
import "./getintouch.scss"

import { string_translation } from "../../../utils"
import {useStaticQuery, graphql, navigate} from "gatsby"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"
import Cf7FormWrapper from "../cf7-form-wrapper"
import attentionIcon from "../../../static/images/error-pages/attention-icon.svg"


function Form({ handler, isLoading, isSent, hasError, validationErrors,stringTranslations }) {

  const [formState, setFormState] = useState({})
  const handleFieldChange = (field, e) => {
    setFormState({
      ...formState,
      [field]: e.target.value,
    })
  }

  const handleFormSubmit = (e) => {
    handler(e, formState)
  }

  const ErrorMessages = (errors) => {
    return (
      <div className="errorMessageContainer">
        <div className="errorTitleContainer">
          <img src={attentionIcon} className="attentionIcon" alt={string_translation(stringTranslations, "submission_error", locale)}/>
          <span className="errorMessage">{string_translation(stringTranslations, "submission_error", locale)}</span>
        </div>
        <div className="errorBody">
          <ul>
            {errors.errors?.map((e, index) => (
              <li key={"e"+index}>{string_translation(stringTranslations, e.into.split(".").pop(), locale)}: {e.message}</li>
            ))}
          </ul>
          <span className="tryAgain">{string_translation(stringTranslations, "try_again", locale)}</span>
        </div>
      </div>
    );
  }

  const {
    locale, // ar
  } = useContext(TranslateUrlsContext)

  if(isSent) {
    navigate("/"+locale+'/success')
  }
  return (
    <form onSubmit={handleFormSubmit} >
      {/* <div>isLoading: {isLoading ? "Loading" : "false"}</div>
      <div>isSent: {isSent ? "Sent" : "false"}</div>
      <div>Error: {hasError || "null"}</div> */}

<div className="errorsParent">{validationErrors ? <ErrorMessages errors={validationErrors} /> : ""}</div>

    <div className="get-in-touch-form">
      <div className="logo">
        <div className="desktop-show">
          <img src="images/invest/form-logo-2.svg" alt="Form Logo" />
        </div>
        <div className="mobile-show">
          <img
            src="images/invest/form-logo-mobile-2.svg"
            alt="Form Logo Mobile"
          />
        </div>
      </div>
      <div className="all-labels-wrappers">
        <div className="label-wrapper">
          <div className="col-group">
            <div className="col-dt-6 label-1">
              <input
                type="text"
                placeholder={string_translation(stringTranslations,"fname", locale)}
                // value={firstname}
                onChange={(e) => handleFieldChange("fname", e)}
              />
            </div>
            <div className="col-dt-6 label-2">
              <input
                type="text"
                placeholder={string_translation(stringTranslations,"lname", locale)}
                // value={lastname}
                onChange={(e) => handleFieldChange("lname", e)}
              />
            </div>
          </div>
        </div>
        <div className="label-wrapper">
          <div className="col-group">
            <div className="col-dt-6 label-1">
              <input
                type="text"
                placeholder={string_translation(stringTranslations,"email", locale)}
                // value={email}
                onChange={(e) => handleFieldChange("email", e)}
              />
            </div>
            <div className="col-dt-6 label-2">
              <input
                type="text"
                placeholder={string_translation(stringTranslations,"phone", locale)}
                // value={number}
                onChange={(e) => handleFieldChange("phone", e)}
              />
            </div>
          </div>
        </div>
        <div className="label-wrapper">
          <div className="col-group">
            <div className="col-dt-6 label-1">
              <input
                type="text"
                placeholder={string_translation(stringTranslations,"company", locale)}
                // value={companyname}
                onChange={(e) => handleFieldChange("company ", e)}
              />
            </div>
            <div className="col-dt-6 label-2">
              <div className="select-wrapper">
                <select
                  // value={investmenttype}
                  onChange={(e) => handleFieldChange("invest", e)}
                >
                  <option value="" disabled selected>
                  {string_translation(stringTranslations,"invest", locale)}
                  </option>
                  <option>{string_translation(stringTranslations,"retail", locale)}</option>
                  <option>{string_translation(stringTranslations,"hospitality", locale)}</option>
                  <option>{string_translation(stringTranslations,"office_space", locale)}</option>
                  <option>{string_translation(stringTranslations,"residential", locale)}</option>
                  <option>{string_translation(stringTranslations,"other", locale)}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="label-wrapper last-label-wrapper">
          <div className="label">
            <input
              type="text"
              placeholder={string_translation(stringTranslations,"msg", locale)}

              // value={message}
              onChange={(e) => handleFieldChange("msg", e)}
            />
          </div>
        </div>
        <div className="label-wrapper">
          <div className="submit-btn-wrapper">
            <div className="submit-btn">
              <button className="cta-btn" type="submit" disabled={isLoading}>{ isLoading ? "loading..." : string_translation(stringTranslations,"submit", locale)}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>)


}
export default function InvestGetInTouch({data}) {
   
  const {
    locale, // ar
  } = useContext(TranslateUrlsContext)

  const {stringTranslations, site} = useStaticQuery(graphql`query {
    stringTranslations {
      title_ar:translateString(language: AR, string: "GET IN TOUCH TITLE")
      title_en:translateString(language: EN, string: "GET IN TOUCH TITLE")
      subtitle_ar:translateString(language: AR, string: "GET IN TOUCH SUBTITLE")
      subtitle_en:translateString(language: EN, string: "GET IN TOUCH SUBTITLE")

      fname_ar:translateString(language: AR, string: "FIRST NAME")
      fname_en:translateString(language: EN, string: "FIRST NAME")
      lname_ar:translateString(language: AR, string: "LAST NAME")
      lname_en:translateString(language: EN, string: "LAST NAME")
      email_ar:translateString(language: AR, string: "EMAIL")
      email_en:translateString(language: EN, string: "EMAIL")
      phone_ar:translateString(language: AR, string: "PHONE")
      phone_en:translateString(language: EN, string: "PHONE")
      company_ar:translateString(language: AR, string: "COMPANY NAME")
      company_en:translateString(language: EN, string: "COMPANY NAME")
      invest_ar:translateString(language: AR, string: "TYPE OF INVESTMENT")
      invest_en:translateString(language: EN, string: "TYPE OF INVESTMENT")
      msg_ar:translateString(language: AR, string: "MESSAGE")
      msg_en:translateString(language: EN, string: "MESSAGE")
      submit_ar:translateString(language: AR, string: "SUBMIT")
      submit_en:translateString(language: EN, string: "SUBMIT")
      try_again_ar:translateString(language: AR, string: "TRY AGAIN")
      try_again_en:translateString(language: EN, string: "TRY AGAIN")
      submission_error_ar:translateString(language: AR, string: "SUBMISSION ERROR")
      submission_error_en:translateString(language: EN, string: "SUBMISSION ERROR")

      retail_ar:translateString(language: AR, string: "RETAIL")
      retail_en:translateString(language: EN, string: "RETAIL")
      hospitality_ar:translateString(language: AR, string: "HOSPITALITY")
      hospitality_en:translateString(language: EN, string: "HOSPITALITY")
      office_space_ar:translateString(language: AR, string: "OFFICE SPACE")
      office_space_en:translateString(language: EN, string: "OFFICE SPACE")
      residential_ar:translateString(language: AR, string: "RESIDENTIAL")
      residential_en:translateString(language: EN, string: "RESIDENTIAL")
      other_ar:translateString(language: AR, string: "OTHER")
      other_en:translateString(language: EN, string: "OTHER")

    }
    site {
      siteMetadata {
        formSubmissionUrl
      }
    }
  }`)

  return (
    <>
      <section className="get-in-touch">
        <div className="container">
          <div className="form-wrapper">
            <div className="small-title">{string_translation(stringTranslations,"subtitle", locale)}</div>
            <div className="big-title h2">{string_translation(stringTranslations,"title", locale)}</div>
            <div className="get-in-touch-form-wrapper">
              <Cf7FormWrapper siteUrl={site.siteMetadata.formSubmissionUrl} formId={data}>
                <Form stringTranslations={stringTranslations}/>
              </Cf7FormWrapper>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
