import React from "react"
import "./firstinvest.scss"
import { createMarkup } from "../../../utils"

export default function FirstInvest(props) {
  return (
    <>
      <section className="landing-video">
        <div className="container">
          <div className="landing-video-wrapper">
            <img src={props.data.video?.localFile?.publicURL} alt={props.data.investmentOpportunities?.title}/>
          </div>
        </div>
      </section>
      <section className="combo-component">
        <div className="container">
            <div className="col-group centering-components">
                <div className="col-dt-4">
                    <div className="big-title h2" >
                        {props.data.quote}
                    </div>
                </div>
                <div className="col-dt-2 center-logo-wrapper">
                    <div className="center-logo ">
                        <div className="mobile-show">
                            <img src="/images/footer/footer-top-desktop.svg" alt="Footer Top Desktop"/>
                        </div>
                    </div>
                    <div className="center-logo ">
                        <div className="desktop-show">
                            <img src="/images/invest/center-logo.svg" alt="Center Logo"/>
                        </div>
                    </div>
                </div>
                <div className="col-dt-6">
                    <div className="combo-text" dangerouslySetInnerHTML={createMarkup(props.content)}>
                        
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}