import React from "react"

import "../styles/style.scss"
import "../styles/css/swiper-bundle.min.css"
import Seo from '../components/seo'
import Layout from "../components/layout"
import WhoWeAre from "../components/WhoWeAre"
import FirstInvest from "../components/FirstInvestWithUs"
import ProjectInNumber from "../components/ProjectInNumber"
import BaordMessage from "../components/BoardMessage"
import Opportunities from "../components/InvestmentOpportunities"
import InvestGetInTouch from "../components/InvestGetInTouch"
import { graphql } from "gatsby"
import { useContext } from "react"
import { TranslateUrlsContext } from "gatsby-plugin-translate-urls"
export default function InvestWithUs(pageData) {
  const data = pageData?.data

  const {
    locale, // ar
  } = useContext(TranslateUrlsContext)
  let seo = pageData?.pageContext?.node?.seo
  let translatedURI = pageData?.pageContext?.node?.translations[0]?.uri

  return (
    <>
      <Seo data={seo}/>
      <Layout translatedURI={translatedURI}>
        <WhoWeAre
          title={data.wpPage.pageSubtitle?.subtitle}
          name={data.wpPage?.title}
          description={data.wpPage.pageDescription?.pageDescription}
        />
        
        <FirstInvest data={data.wpPage.InvestWithUsFields} content={data.wpPage.content}/>
        <div className="project-in-number-invest">
          <ProjectInNumber
            title1="Project"
            title2="in number"
            data={data.wpPage.InvestWithUsFields.investProjectInNumber}
          />
        </div>
        <BaordMessage data={data.wpPage.InvestWithUsFields.messageFromTheBoard} />
        <Opportunities data={data.wpPage.InvestWithUsFields.investmentOpportunities} slider={data.wpPage.InvestWithUsFields.investSlider} locale={locale}/>
        <InvestGetInTouch data={data?.wpPage?.InvestWithUsFields?.investContactForm} />
      </Layout>
    </>
  )
}


export const pageQuery = (graphql` query investWithUsData($databaseId:Int) {
  wpPage(databaseId: {eq: $databaseId}) {
    id
    title
    pageSubtitle {
      fieldGroupName
      subtitle
    }
    content
    featuredImage {
      node {
        localFile {
          publicURL
        }
      }
    }
    pageDescription {
      pageDescription
    }
    InvestWithUsFields {
      investmentOpportunities {
        text
        title
      }
      messageFromTheBoard {
        image {
          localFile {
            publicURL
          }
        }
        message
      }
      investProjectInNumber {
        ipnDesktopImage {
          localFile {
            publicURL
          }
        }
        ipnMobileImage {
          localFile {
            publicURL
          }
        }
        ipnText
      }
      quote
      investSlider {
        sliderText
        sliderTitle
        sliderImage {
          localFile {
            publicURL
          }
        }
      }
      video {
        localFile {
          publicURL
        }
        mimeType
      }
      investContactForm
    }
  }
}`);